import React from "react";
import Aboutmain from "../../components/Onas/Aboutmain";
import AboutQA from "../../components/Onas/AboutQA";

const About = () => {
  return (
    <div>
      <Aboutmain />
      <AboutQA />
    </div>
  );
};

export default About;
