import React from "react";
import LoginMain from "../../components/Login/LoginMain";

const Login = () => {
  return (
    <div>
      <LoginMain />
    </div>
  );
};

export default Login;
