import React from "react";
import Kontakmain from "../../components/Kontakt/Kontakmain";

const Kontakt = () => {
  return (
    <div>
      <Kontakmain />
    </div>
  );
};

export default Kontakt;
