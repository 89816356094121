import React from "react";
import CategoryDisplay from "../../components/Products/CategoryDisplay";

const Shop = () => {
  return (
    <div>
      <CategoryDisplay />
    </div>
  );
};

export default Shop;
