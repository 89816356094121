import React from "react";
import AllBlogs from "../../components/Blog/AllBlogs";

const Blogs = () => {
  return (
    <div>
      <AllBlogs />
    </div>
  );
};

export default Blogs;
