import React from "react";
import OfertaMain from "../../components/Oferta/OfertaMain";

const Oferta = () => {
  return (
    <div>
      <OfertaMain />
    </div>
  );
};

export default Oferta;
